<template>
  <div class="login-page container-fluid">
    <div class="row">
      <div class="col-12 col-sm-12 col-md-6 col-xl-3 mx-auto login-container">
        <img src="@/assets/logo.png" alt="Expertel IQ" class="img-fluid logo" />
        <transition name="slide-fade">
          <form @submit.prevent="login()" v-if="formShow == 'login'">
            <input
              v-model="email"
              type="text"
              placeholder="Email"
              class="form-control"
            />
            <input
              v-model="password"
              type="password"
              placeholder="Password"
              class="form-control"
            />
            <button type="submit" class="button-expertel text-center">
              LOGIN
            </button>
            <a type="button" class="link mt-3" @click="formToShow('reset')">
              Forgot your password?
            </a>
          </form>
          <!---------------------------------------------------------->
          <form @submit.prevent="recover_Password()" v-if="formShow == 'reset'">
            <h2 class="mb-4">Reset your password</h2>
            <input
              v-model="email"
              type="text"
              placeholder="Email"
              class="form-control"
            />
            <p class="my-4">Enter your email address to reset your password</p>
            <button type="submit" class="button-expertel text-center">
              RESET
            </button>
            <button
              class="link mt-3 float-left color-orange"
              @click.prevent="formToShow('login')"
            >
              <i class="far fa-arrow-left mr-1"></i> Back
            </button>
          </form>
          <!---------------------------------------------------------->
          <div v-if="formShow == 'recovery'">
            <p class="my-4">
              An email with further instructions was sent to the submitted email
              address. If you don't receive a message in 5 minutes, check the
              junk folder, if you are still experiencing problems, contact
              support at
              <a href="mailto:helpdesk@expertel.ca">helpdesk@expertel.ca</a>.
            </p>
            <a
              class="link mt-3 color-orange"
              @click.prevent="formToShow('login')"
            >
              <i class="far fa-arrow-left mr-1"></i> Back
            </a>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["login_data"],
  data() {
    return {
      email: "",
      password: "",
      login_status: [],
      formShow: "login",
      login_token: ""
    };
  },
  created() {
    const self = this;

    if (
      this.$route.params.token !== undefined &&
      this.$route.params.token !== ""
    ) {
      this.login_token = this.$route.params.token;

      // GET LOGIN DATA
      this.axios({
        method: "get",
        url: `https://api.expertel.ca/api/v1/user/me`,
        responseType: "json",
        headers: {
          Authorization: "Bearer " + self.login_token
        }
      })
        .then(function(response) {
          // SAVE LOGIN DATA
          self.login_status = response.data;

          if (response.data.user.type == "admin") {
            location.href = `https://iq.expertel.ca/admin/login?token=${response.data.token}`;
          } else {
            self.$emit("loginSuccess", response.data);
           
          }
        })
        .catch(error => this.makeToast("Error", error, "danger"));
    }

    if (this.login_data.length !== 0) {
      if (this.login_data.user.type == "admin") {
        location.href = `https://iq.expertel.ca/admin/login?token=${this.login_data.token}`;
      } else {
        this.$router.push("/dashboard");
      }
    }
  },
  methods: {
    login() {
      const self = this;

      //FROMDATA TO SEND
      let formData = new FormData();
      formData.append("email", this.email);
      formData.append("password", this.password);

      // GET LOGIN DATA
      this.axios({
        method: "post",
        url: `https://api.expertel.ca/api/v1/login`,
        data: formData,
        responseType: "json"
      })
        .then(function(response) {
          //alert();
          // SAVE LOGIN DATA
          self.login_status = response.data;

          if (response.data.user.type == "admin") {
            location.href = `https://iq.expertel.ca/admin/login?token=${response.data.token}`;
          } else {
            self.$emit("loginSuccess", response.data);
          }
        })
        .catch(error => this.makeToast("ERROR", error, "danger"));
    },
    recover_Password() {
      const self = this;

      if (this.email !== "") {
        //SUBMIT RESES
        this.axios({
          method: "post",
          url: `https://api.expertel.ca/api/v1/forgot_password`,
          data: {
            email: this.email
          },
          responseType: "json"
        })
          .then(function() {
            self.formShow = "recovery";
          })
          .catch(error => this.makeToast("Error", error, "danger"));
      } else {
        this.makeToast(
          "Empty Field",
          "Please fill your email address",
          "danger"
        );
      }
    },
    makeToast(title, message, variant = null) {
      this.$bvToast.toast(`${message}`, {
        title: title,
        autoHideDelay: 5000,
        appendToast: true,
        variant: variant
      });
    },
    formToShow(form) {
      this.formShow = form;
    }
  }
};
</script>

<style lang="scss">
/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all 0.6s ease;
}
.slide-fade-leave-active {
  transition: all 0.6s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
</style>
